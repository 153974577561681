import { Dialog, Transition } from "@headlessui/react"
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { Fragment } from "react"
import useConfirm from "src/hooks/useConfirm"

export default function ModalUnsavedChanges() {
  const { isOpen = false, proceed, cancel } = useConfirm()

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-xl transform overflow-hidden rounded-2xl 
              bg-white p-4 text-left align-middle shadow-xl transition-all"
              >
                <div className="mb-4 flex justify-end ">
                  <button onClick={cancel}>
                    <XMarkIcon className="h-5 w-5 text-[#676879]" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex items-center gap-2 px-6 text-lg font-semibold leading-6 text-gray-900"
                >
                  <span>
                    <ExclamationTriangleIcon className="h-9 w-8 text-[#FCB900]" />
                  </span>
                  Unsaved changes
                </Dialog.Title>
                <div className="mt-2 px-6">
                  <p className="text-lg text-black">
                  You have unsaved changes, Are you sure you want to leave?
                  </p>
                </div>
                <div className="mt-14 flex justify-end gap-4 px-6">
                  <button
                    type="button"
                    className="inline-flex gap-1 rounded-lg border border-purple-unfold bg-purple-unfold px-6 py-2.5
                    text-sm font-medium text-white hover:bg-secondary hover:text-purple-unfold"
                    onClick={proceed}
                  >
                    Ok
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent 
                    bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 
                    focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 
                    focus-visible:ring-offset-2"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
