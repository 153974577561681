import wretch from "wretch"
import FormDataAddon from "wretch/addons/formData"
import QueryStringAddon from "wretch/addons/queryString"

const apiKey = { "unfold-api-key": process.env.REACT_APP_UNFOLD_API_KEY }

export const api = wretch(`${process.env.REACT_APP_BASE_URL_API}/api/v1`)
  .headers(apiKey)
  .addon(FormDataAddon)
  .addon(QueryStringAddon)

export default api
