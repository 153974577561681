import { TLead } from "src/types/Leads"
import { create } from "zustand"

type LeadsFormStore = {
  leadsData: TLead | any
  setLeadsData: (newData: TLead) => void

  openCreate: boolean
  setOpenCreate: () => void

  openUpdate: boolean
  setOpenUpdate: () => void

  openView: boolean
  setOpenView: () => void

  idArray: Array<string> | any
  setIdArray: (bulkId: Array<string>) => void

  openBulkUpdate: boolean
  setOpenBulkUpdate: () => void
}

 const useQuickFormStore = create<LeadsFormStore>()((set) => ({
  leadsData: {},
  setLeadsData: (newData: TLead) => set(() => ({ leadsData: newData })),

  openCreate: false,
  setOpenCreate: () => set((state) => ({ openCreate: !state.openCreate })),

  openUpdate: false,
  setOpenUpdate: () => set((state) => ({ openUpdate: !state.openUpdate })),

  openView: false,
  setOpenView: () => set((state) => ({ openView: !state.openView })),

  idArray: [],
  setIdArray: (bulkId: Array<string>) => set(() => ({ idArray: bulkId })),

  openBulkUpdate: false,
  setOpenBulkUpdate: () => set((state) => ({ openBulkUpdate: !state.openBulkUpdate })),
}))

export default useQuickFormStore