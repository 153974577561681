import { Dialog, Listbox, Transition } from "@headlessui/react"
import { ArrowTopRightOnSquareIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import {
  ArrowSmallRightIcon,
  EllipsisHorizontalIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid"
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { format } from "date-fns"
import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import { ClipboardCheck, Pencil, Trash, XCircle } from "src/components/icon"
import LoadingFullScreen from "src/components/LoadingFullScreen"
import LoadingSpinner from "src/components/LoadingSpinner"
import Pagination from "src/components/Pagination"
import api from "src/lib/wretch"
import useAuthStore from "src/store/useAuthStore"
import useQuickFormStore from "src/store/useQuickFormStore"
import { TLead, TLeadsPaginatedData } from "src/types/Leads"
import { create } from "zustand"
import LeadsFormOverlay, { AddFormButton } from "./components/LeadsFormOverlay"
import {
  leadsNotifyDisqualify,
  leadsNotifyQualify,
  leadsNotifyRollback,
} from "./components/LeadsNotification"
import DataNotFound from "src/components/DataNotFound"

const LeadsData = createContext<TLeadsPaginatedData>({} as TLeadsPaginatedData)

type Menu = {
  id: string
  setId: (id: string) => void
}
const useMenuStore = create<Menu>((set) => ({
  id: "",
  setId: (id) => set(() => ({ id: id })),
}))

async function fetchLeadsData(
  jwtToken: string,
  pagination: number = 1,
  rowPerPage: number
): Promise<TLeadsPaginatedData> {
  const response = await api
    .auth(`Bearer ${jwtToken}`)
    .get(`/leads?page=${pagination}&limit=${rowPerPage}`)
    .json()
  return response as TLeadsPaginatedData
}

function Leads() {
  const jwtToken = useAuthStore((state) => state.token)
  const [rowSize, setRowSize] = useState(25)
  const [paginationCount, setPaginationCount] = useState(1)
  const { data, isSuccess, isRefetching } = useQuery({
    queryKey: ["leads", paginationCount],
    queryFn: () => fetchLeadsData(jwtToken, paginationCount, rowSize),
    keepPreviousData: true,
  })

  const [searchResults, setSearchResults] = useState(data?.data.leads as TLead[])
  const [selectedRows, setSelectedRows] = useState<Array<string>>([])

  const setId = useMenuStore((state) => state.setId)

  function selectRows(id: string) {
    setId("")
    setSelectedRows((current) => {
      if (current.includes(id)) {
        // row is already selected. Unselect but keep the others
        return current.filter((entry) => entry !== id)
      } else {
        // add row to the current list of selected ones
        return [...current, id]
      }
    })
  }

  function selectAllRows() {
    setId("")
    const allRows = data?.data.leads.map((entry) => entry._id) as string[]
    if (selectedRows.length < data?.data.leads.length!) {
      setSelectedRows(allRows)
    } else {
      setSelectedRows([])
    }
  }

  useEffect(() => {
    if (isRefetching) {
      setSelectedRows([])
      setSearchResults(data?.data.leads as TLead[])
    }
    if (isSuccess) {
      setSelectedRows([])
      setSearchResults(data?.data.leads as TLead[])
    }
  }, [data?.data, isSuccess, isRefetching])

  if (data?.leadsCount === 0) return <DataNotFound />

  if (data?.data.leads === undefined)
    return (
      <main className="px-2">
        <h1 className="my-8 text-5xl font-bold leading-tight tracking-tight">Leads</h1>
        <LoadingFullScreen />
      </main>
    )

  return (
    <LeadsData.Provider value={data}>
      <LeadsFormOverlay>
        <main className="mb-10 px-2">
          <h1 className="my-8 text-5xl font-bold leading-tight tracking-tight">Leads</h1>
          <section className="flex justify-between">
            <div className="w-1/2">
              <SearchBar data={data?.data.leads as TLead[]} setSearchResults={setSearchResults} />
            </div>
            <div>
              <div className="flex w-fit justify-end gap-2">
                {selectedRows.length > 1 && (
                  <div className="mr-2">
                    <div>
                      <BulkActionButton idArray={selectedRows} />
                    </div>
                  </div>
                )}
                <label htmlFor="filter" className="sr-only">
                  Filter
                </label>
                <div className="relative w-[150px]">
                  <input
                    type="text"
                    id="filter"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 
                    p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="Filter"
                    required
                  />
                  <div
                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center 
                    pr-3 text-black"
                  >
                    <FunnelIcon className="h-5 w-5 text-purple-unfold" />
                  </div>
                </div>
                <AddFormButton />
              </div>
            </div>
          </section>
          <section className="relative overflow-x-auto rounded bg-[#F7F7F7] p-2">
            <table className="w-full text-left text-sm text-gray-500 ">
              <thead className="text-xs uppercase text-gray-700">
                <tr>
                  <th scope="col" className="px-1 py-3">
                    <form>
                      <input
                        id="all"
                        name="all"
                        type="checkbox"
                        readOnly
                        onClick={selectAllRows}
                        checked={selectedRows.length === data?.data.leads.length}
                        className="form-check-input float-left mr-2 mt-1 cursor-pointer appearance-none 
                      rounded bg-contain bg-center bg-no-repeat align-top accent-purple-unfold transition duration-200
                    checked:border-purple-unfold checked:bg-purple-unfold focus:outline-none"
                      />
                    </form>
                  </th>
                  <th scope="col" className="px-1 py-3">
                    ID
                  </th>
                  <th scope="col" className="px-1 py-3">
                    Full Name
                  </th>
                  <th scope="col" className="px-1 py-3">
                    Business Type
                  </th>
                  <th scope="col" className="px-1 py-3">
                    Service
                  </th>
                  <th scope="col" className="px-1 py-3">
                    City
                  </th>
                  <th scope="col" className="px-1 py-3 text-center">
                    Status
                  </th>
                  <th scope="col" className="py-3 pl-1 pr-0">
                    Created On
                  </th>
                  <th scope="col" className="px-1 py-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <Suspense
                fallback={
                  <tbody>
                    <tr>
                      <td colSpan={9} className="h-[200px]">
                        <LoadingSpinner />
                      </td>
                    </tr>
                  </tbody>
                }
              >
                {searchResults === data?.data.leads ? (
                  <TableBody
                    data={data?.data.leads as TLead[]}
                    selectRows={selectRows}
                    selectedRows={selectedRows}
                  />
                ) : (
                  <TableBody
                    data={searchResults}
                    selectRows={selectRows}
                    selectedRows={selectedRows}
                  />
                )}
              </Suspense>
            </table>
          </section>
          <div className="flex justify-between">
            <RowPage
              range={[5, 10, 25]}
              size={rowSize}
              setSize={setRowSize}
              paginationCount={paginationCount}
            />
            <Pagination
              paginationCount={paginationCount}
              updatePaginationCount={setPaginationCount}
              maxPaginationCount={data?.totalPages as number}
            />
          </div>
        </main>
      </LeadsFormOverlay>
    </LeadsData.Provider>
  )
}

type SearchBarProps = {
  data: TLead[]
  setSearchResults: Dispatch<SetStateAction<TLead[]>>
}

function SearchBar(props: SearchBarProps) {
  const { data, setSearchResults } = props

  const handleSubmit = (e: { preventDefault: () => any }) => e.preventDefault()
  const handleSearchChange = (e: { target: { value: string } }) => {
    if (!e.target.value) return setSearchResults(data)

    const newSearchResults = data.filter(
      (item) =>
        item.firstName.toLowerCase().includes(e.target.value) ||
        item.lastName.toLowerCase().includes(e.target.value) ||
        item.city.toLowerCase().includes(e.target.value)
    )

    setSearchResults(newSearchResults)
  }

  return (
    <form className="mb-10 flex w-full items-center" onSubmit={handleSubmit}>
      <label htmlFor="simple-search" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5" />
        </div>
        <input
          type="text"
          id="simple-search"
          onChange={handleSearchChange}
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 
          p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Search"
          required
        />
      </div>
      <button
        type="submit"
        className="ml-2 flex gap-1 rounded-lg border border-purple-unfold bg-purple-unfold p-2.5 
        text-sm font-medium text-white hover:bg-secondary hover:text-purple-unfold 
        focus:outline-none focus:ring-4 focus:ring-purple-unfold"
      >
        <p>Search</p>
        <MagnifyingGlassIcon className="h-5 w-5" />
        <span className="sr-only">Search</span>
      </button>
    </form>
  )
}

type TableBodyProps = {
  data?: TLead[]
  selectRows: (id: string) => void
  selectedRows: Array<string>
}
function TableBody({ data, selectRows, selectedRows }: TableBodyProps) {
  const setLeadsData = useQuickFormStore((state) => state.setLeadsData)
  const setOpenView = useQuickFormStore((state) => state.setOpenView)

  function handleView(data: TLead) {
    setLeadsData(data)
    setOpenView()
  }

  return (
    <tbody>
      {data?.map((item, index) => {
        const isSelected = selectedRows.includes(item._id)
        const disabled = (selectedRows.length > 0 && !isSelected) || selectedRows.length > 1
        return (
          <tr key={item._id}>
            <td className="border-t border-gray-300 px-1 py-4">
              <form>
                <input
                  type="checkbox"
                  className="form-check-input float-left mr-2 mt-1 cursor-pointer appearance-none 
                  rounded bg-contain bg-center bg-no-repeat align-top accent-purple-unfold transition duration-200
                  checked:border-purple-unfold checked:bg-purple-unfold focus:outline-none"
                  name={item.firstName}
                  id={item._id}
                  checked={isSelected}
                  readOnly
                  onClick={() => selectRows(item._id)}
                />
              </form>
            </td>
            <td onClick={() => handleView(item)} className="border-t border-gray-300 px-1 py-4">
              {index + 1}
            </td>
            <td
              onClick={() => handleView(item)}
              className="border-t border-gray-300 px-1 py-4 font-medium text-gray-900"
            >
              {item.firstName} {item.lastName}
            </td>
            <td onClick={() => handleView(item)} className="border-t border-gray-300 px-1 py-4">
              {item.businessType}
            </td>
            <td onClick={() => handleView(item)} className="border-t border-gray-300 px-1 py-4">
              {item.service.map((item) => (
                <p key={item}>{item}</p>
              ))}
            </td>
            <td onClick={() => handleView(item)} className="border-t border-gray-300 px-1 py-4">
              {item.city}
            </td>
            <td onClick={() => handleView(item)} className="border-t border-gray-300 pr-3">
              <div
                className={` rounded-xl px-2 py-1 text-center ${
                  item.status === "Qualified"
                    ? "bg-[#EBF6EB] text-[#31AA27]"
                    : item.status === "Open"
                    ? "bg-[#FFF4E4] text-[#FBA422]"
                    : "bg-[#FFF0F0] text-[#F93232]"
                }`}
              >
                {item.status}
              </div>
            </td>
            <td
              onClick={() => handleView(item)}
              className="whitespace-nowrap border-t border-gray-300 py-4 pl-1 pr-0"
            >
              {format(new Date(item.createdOn), "dd-MMM-yyyy")}
            </td>
            <td className="m-0 border-t border-gray-300 px-1 py-4">
              <div className="flex justify-center">
                <ActionButton id={item._id} disabled={disabled} data={item} />
              </div>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

type RowPageProps = {
  range: number[]
  size: number
  setSize: Dispatch<SetStateAction<number>>
  paginationCount: number
}
function RowPage({ range, size, setSize, paginationCount }: RowPageProps) {
  const queryClient = useQueryClient()
  const handleClick = () => {
    queryClient.cancelQueries({ queryKey: ["leads", paginationCount] })
    queryClient.invalidateQueries({ queryKey: ["leads", paginationCount] })
  }
  return (
    <div className="relative">
      <Listbox value={size} onChange={setSize}>
        <Listbox.Button
          className="cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md 
          focus:outline-none"
        >
          {size}
        </Listbox.Button>
        <Listbox.Options
          className="absolute left-0 mt-2 w-full rounded-md bg-white py-1 
          text-base shadow-lg"
        >
          {range.map((value) => (
            <Listbox.Option
              key={value}
              value={value}
              onClick={handleClick}
              className="pl-3 hover:bg-purple-unfold hover:text-white"
            >
              {value}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

type MenuProps = { main: string; more: boolean; other: boolean }
type BulkMenuProps = { main: boolean; more: boolean; other: boolean }
type useMenuReducerProps = {
  menu?: MenuProps
  updateMenuState: Dispatch<Partial<MenuProps>> | Dispatch<Partial<BulkMenuProps>>
}
type BulkActionButtonProps = { idArray: Array<string> }

function BulkActionButton({ idArray }: BulkActionButtonProps) {
  const [menu, updateMenuState] = useReducer(
    (prev: BulkMenuProps, next: Partial<BulkMenuProps>) => {
      return { ...prev, ...next }
    },
    { main: false, more: false, other: false }
  )

  if (menu.more) return <BulkMoreMenu idArray={idArray} updateMenuState={updateMenuState} />

  if (menu.other) return <BulkOtherMenu idArray={idArray} updateMenuState={updateMenuState} />

  if (menu.main) {
    return (
      <div className="flex gap-0.5 text-[9px]">
        <button
          className="bg-[#CFD6DA] px-[9px] py-4"
          onClick={() =>
            updateMenuState({
              more: true,
            })
          }
        >
          <span>
            <EllipsisHorizontalIcon className="h-6 w-6" />
          </span>
          More
        </button>
        <button
          className="bg-[#BAF0D8] px-[9px] py-4 text-[#74E0B0]"
          onClick={() => updateMenuState({ other: true })}
        >
          <span>
            <ArrowSmallRightIcon className="h-6 w-6" />
          </span>
          other
        </button>
        <button
          className="bg-[#CFD6DA] px-[9px] py-4"
          onClick={() => updateMenuState({ main: false })}
        >
          <XMarkIcon className="h-4 w-4 text-[#676879] " />
        </button>
      </div>
    )
  }

  return (
    <button
      type="submit"
      onClick={() => updateMenuState({ main: true })}
      className="rounded-lg border border-purple-unfold bg-purple-unfold px-6 py-2.5 text-sm 
      font-medium text-white hover:bg-secondary hover:text-primary 
      focus:outline-none focus:ring-4 focus:ring-primary"
    >
      Action
    </button>
  )
}

type BulkMoreMenuProps = useMenuReducerProps & { idArray: Array<string> }

function BulkMoreMenu({ updateMenuState, idArray }: BulkMoreMenuProps) {
  const setIdArray = useQuickFormStore((state) => state.setIdArray)
  const setOpenBulkUpdate = useQuickFormStore((state) => state.setOpenBulkUpdate)

  function handleBulkUpdate(idArray: Array<string>) {
    setIdArray(idArray)
    setOpenBulkUpdate()
  }

  return (
    <div className="min-w-[170px] rounded-lg border border-gray-200 bg-white p-2 shadow-md hover:bg-gray-100">
      <div className="flex w-full flex-col gap-y-2 ">
        <div className="flex justify-between gap-4">
          <button
            type="submit"
            onClick={() => handleBulkUpdate(idArray)}
            className="flex w-full gap-1 rounded-lg p-1 text-sm font-medium
            leading-4 text-dark-gray focus:outline-none focus:ring-1"
          >
            <span>
              <Pencil className="mr-1 h-4 w-4" />
            </span>
            Edit
          </button>
          <button onClick={() => updateMenuState({ more: false })}>
            <XMarkIcon className="h-4 w-4 text-[#676879]" />
          </button>
        </div>
        <BulkDelete idArray={idArray} />
      </div>
    </div>
  )
}

const leadsNotifyBulkDeleted = (
  leadsData: TLeadsPaginatedData,
  idArray: string[],
  jwtToken: string,
  deletedData: Array<TLead>
) => {
  const notifyDeleted = toast.custom(
    <div
      className="flex w-[270px] items-center justify-between gap-2 rounded-md bg-[#FCEDE9] 
      px-4 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2 text-red-600">
          <ExclamationTriangleIcon className="h-4 w-4" />
        </span>
        Deleted Successfully
      </div>
      <button type="button" onClick={rollbackBulkDelete}>
        Undo
      </button>
    </div>
  )

  async function rollbackBulkDelete() {
    toast.remove(notifyDeleted)
    await Promise.all(
      deletedData.map((payload) =>
        api
          .auth(`Bearer ${jwtToken}`)
          .post(payload, "/leads")
          .fetchError((err) => console.log(err))
          .res()
          .catch((err) => console.log(err))
      )
    )
    leadsNotifyRollback()
  }

  return notifyDeleted
}

function BulkDelete({ idArray }: { idArray: Array<string> }) {
  const leadsData = useContext(LeadsData)
  const jwtToken = useAuthStore((state) => state.token)
  const [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const bulkDelete = useMutation({
    mutationFn: closeModalAndDelete,
    onMutate: async () => {
      const newLeadsData = leadsData
      const filteredData = leadsData.data.leads.filter((e) => !idArray.includes(e._id))
      newLeadsData.data.leads = filteredData
      queryClient.setQueryData(["leads", leadsData.currentPage], newLeadsData)
      closeModal()
    },
    onError: () => {
      queryClient.setQueryData(["leads", leadsData.currentPage], leadsData)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["leads", leadsData.currentPage] })
    },
  })

  async function closeModalAndDelete() {
    const payload = { leadIds: idArray }

    await api
      .auth(`Bearer ${jwtToken}`)
      .json(payload)
      .delete("/leads/bulk")
      .fetchError((err) => console.log(err))
      .res()
      .catch((err) => console.log(err))
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="submit"
        onClick={() => openModal()}
        className="flex gap-1 rounded-lg p-1 text-sm font-medium  
        leading-4 text-dark-gray focus:outline-none focus:ring-1"
      >
        <span>
          <Trash className="mr-1 h-4 w-4" />
        </span>
        Delete
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="mb-4 flex justify-end ">
                    <button onClick={closeModal}>
                      <XMarkIcon className="h-5 w-5 text-[##676879]" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-2 px-6 text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>
                      <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                    </span>
                    Are you sure you want to delete this file?
                  </Dialog.Title>

                  <div className="mt-14 flex justify-end gap-4 px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-gray-100 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 
                      focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={bulkDelete.isLoading}
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-[#E24919] px-4 py-2 text-sm font-medium text-white hover:bg-red-600 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 
                      focus-visible:ring-offset-2"
                      onClick={() => {
                        const deletedData = leadsData.data.leads.filter((e) =>
                          idArray.includes(e._id)
                        )
                        leadsNotifyBulkDeleted(leadsData, idArray, jwtToken, deletedData)
                        bulkDelete.mutate()
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

type BulkOtherMenuProps = useMenuReducerProps & { idArray: Array<string> }

function BulkOtherMenu({ updateMenuState, idArray }: BulkOtherMenuProps) {
  return (
    <div className="min-w-[170px] rounded-lg border border-gray-200 bg-white p-2 shadow-md hover:bg-gray-100">
      <div className="flex w-full flex-col gap-y-2 ">
        <div className="flex justify-between gap-4">
          <BulkQualify idArray={idArray} />
          <button onClick={() => updateMenuState({ other: false })} className="w-fit">
            <XMarkIcon className="h-4 w-4 text-[#676879]" />
          </button>
        </div>
        <BulkDisqualify idArray={idArray} />
      </div>
    </div>
  )
}

type BulkQualifyProps = { idArray: Array<string> }

function BulkQualify({ idArray }: BulkQualifyProps) {
  const jwtToken = useAuthStore((state) => state.token)

  const [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: closeModalAndQualify,
  })

  async function closeModalAndQualify() {
    const payload = {
      leadIds: idArray,
      updateData: {
        status: "Qualified",
      },
    }

    await api
      .auth(`Bearer ${jwtToken}`)
      .patch(payload, "/leads/bulk")
      .fetchError((err) => console.log(err))
      .res((res) => {
        console.log(res)
        queryClient.invalidateQueries({ queryKey: ["leads"] })
        leadsNotifyQualify()
        setIsOpen(false)
      })
      .catch((err) => console.log(err))
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="submit"
        onClick={() => openModal()}
        className="flex w-full gap-1 rounded-lg p-1 text-sm font-medium
        leading-4 text-dark-gray focus:outline-none focus:ring-1"
      >
        <span>
          <ClipboardCheck className="mr-1 h-4 w-4" />
        </span>
        Qualify
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="mb-4 flex justify-end ">
                    <button onClick={closeModal}>
                      <XMarkIcon className="h-5 w-5 text-[##676879]" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-2 px-6 text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>
                      <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                    </span>
                    Qualify
                  </Dialog.Title>

                  <div className="mt-14 flex justify-end gap-4 px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-gray-100 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 
                      focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={mutation.isLoading}
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-[#31AA27] px-4 py-2 text-sm font-medium text-white hover:bg-green-600 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 
                      focus-visible:ring-offset-2"
                      onClick={() => mutation.mutate()}
                    >
                      {mutation.isLoading ? "Loading..." : "Qualify"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

type BulkDisqualifyProps = { idArray: Array<string> }

function BulkDisqualify({ idArray }: BulkDisqualifyProps) {
  const jwtToken = useAuthStore((state) => state.token)

  const [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: closeModalAndDisqualify,
  })

  async function closeModalAndDisqualify() {
    const payload = {
      leadIds: idArray,
      updateData: {
        status: "Disqualified",
      },
    }

    await api
      .auth(`Bearer ${jwtToken}`)
      .patch(payload, "/leads/bulk")
      .fetchError((err) => console.log(err))
      .res((res) => {
        console.log(res)
        queryClient.invalidateQueries({ queryKey: ["leads"] })
        leadsNotifyDisqualify()
        setIsOpen(false)
      })
      .catch((err) => console.log(err))
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="submit"
        onClick={() => openModal()}
        className="flex gap-1 rounded-lg p-1 text-sm font-medium  
        leading-4 text-dark-gray focus:outline-none focus:ring-1"
      >
        <span>
          <XCircle className="mr-1 h-4 w-4" />
        </span>
        Disqualify
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="mb-4 flex justify-end ">
                    <button onClick={closeModal}>
                      <XMarkIcon className="h-5 w-5 text-[##676879]" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-2 px-6 text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>
                      <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                    </span>
                    Qualify
                  </Dialog.Title>

                  <div className="mt-14 flex justify-end gap-4 px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-gray-100 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 
                      focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={mutation.isLoading}
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-[#E24919] px-4 py-2 text-sm font-medium text-white hover:bg-red-600 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 
                      focus-visible:ring-offset-2"
                      onClick={() => mutation.mutate()}
                    >
                      {mutation.isLoading ? "Loading..." : "Disqualify"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

type ActionButtonProps = { id: string; disabled: boolean; data: TLead }

function ActionButton({ id, disabled, data }: ActionButtonProps) {
  const globalIdString = useMenuStore((state) => state.id)
  const setId = useMenuStore((state) => state.setId)

  const [menu, updateMenuState] = useReducer(
    (prev: MenuProps, next: Partial<MenuProps>) => {
      return { ...prev, ...next }
    },
    { main: id, more: false, other: false }
  )

  function openMenu() {
    setId(id)
  }

  function closeMenu() {
    setId("")
  }

  useEffect(() => {
    if (disabled) {
      updateMenuState({ more: false, other: false })
    }
  }, [disabled])

  if (menu.main === globalIdString && menu.other)
    return <OtherMenu idString={globalIdString} data={data} updateMenuState={updateMenuState} />

  if (menu.main === globalIdString && menu.more)
    return <MoreMenu idString={globalIdString} data={data} updateMenuState={updateMenuState} />

  if (menu.main === globalIdString && !disabled) {
    return (
      <div className="flex gap-0.5 text-[9px] ">
        <button
          className="bg-[#CFD6DA] px-[9px] py-2"
          onClick={() => updateMenuState({ more: true })}
        >
          <span>
            <EllipsisHorizontalIcon className="h-6 w-6" />
          </span>
          More
        </button>
        <Link className="bg-[#CFD6DA] px-[9px] py-2" to={`open/${id}`}>
          <span>
            <ArrowTopRightOnSquareIcon className="h-6 w-5" />
          </span>
          open
        </Link>
        <button
          className="bg-[#BAF0D8] px-[9px] py-2 text-[#74E0B0]"
          onClick={() => updateMenuState({ other: true })}
        >
          <span>
            <ArrowSmallRightIcon className="h-6 w-6" />
          </span>
          other
        </button>
        <button className="bg-[#CFD6DA] px-[9px] py-4" onClick={closeMenu}>
          <XMarkIcon className="h-6 w-4 text-[#676879] " />
        </button>
      </div>
    )
  }

  return (
    <button
      type="submit"
      disabled={disabled}
      onClick={openMenu}
      className="rounded-lg border border-purple-unfold
      bg-purple-unfold px-2 py-1 text-sm font-medium 
      text-white invalid:border-pink-500 invalid:text-pink-600 hover:bg-secondary 
      hover:text-primary focus:outline-none focus:ring-4 focus:ring-primary 
      disabled:border-slate-200 disabled:bg-slate-50
      disabled:text-slate-500 disabled:shadow-none"
    >
      Action
    </button>
  )
}

type MoreMenuProps = useMenuReducerProps & { data?: TLead; idString: string }

function MoreMenu({ updateMenuState, data, idString }: MoreMenuProps) {
  const setLeadsData = useQuickFormStore((state) => state.setLeadsData)
  const setOpenUpdate = useQuickFormStore((state) => state.setOpenUpdate)

  function handleUpdate(data: TLead) {
    setLeadsData(data)
    setOpenUpdate()
  }

  return (
    <div className="min-w-[170px] rounded-lg border border-gray-200 bg-white p-2 shadow-md hover:bg-gray-100">
      <div className="flex w-full flex-col gap-y-2 ">
        {/* <Link to="edit"> */}
        <div className="flex justify-between gap-4">
          <button
            type="submit"
            onClick={() => handleUpdate(data!)}
            className="flex w-full gap-1 rounded-lg p-1 text-sm font-medium  
            leading-4 text-dark-gray focus:outline-none focus:ring-1"
          >
            <span>
              <Pencil className="mr-1 h-4 w-4" />
            </span>
            Edit
          </button>
          <button onClick={() => updateMenuState({ more: false })}>
            <XMarkIcon className="h-4 w-4 text-[#676879]" />
          </button>
        </div>
        {/* </Link> */}
        <Delete idString={idString} data={data as TLead} />
      </div>
    </div>
  )
}

function leadsNotifyDeletedWithRollback(
  jwtToken: string,
  values: TLead,
  queryClient: QueryClient,
  queryKey: Array<string | number>
) {
  const notifyDeleted = toast.custom(
    <div
      className="flex w-[270px] items-center justify-between gap-2 rounded-md bg-[#FCEDE9] 
      px-4 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2 text-red-600">
          <ExclamationTriangleIcon className="h-4 w-4" />
        </span>
        Deleted Successfully
      </div>
      <button type="button" className="text-gray-900" onClick={rollbackDelete}>
        Undo
      </button>
    </div>
  )

  async function rollbackDelete() {
    await api
      .auth(`Bearer ${jwtToken}`)
      .post(values, "/leads")
      .res(async () => {
        await queryClient.cancelQueries({ queryKey: queryKey })
        await queryClient.invalidateQueries({ queryKey: queryKey })
        // toast.remove(notifyDeleted)
        leadsNotifyRollback()
      })
      .catch((err) => console.log(err))
  }

  return notifyDeleted
}

function Delete({ idString, data }: { idString: string; data: TLead }) {
  const jwtToken = useAuthStore((state) => state.token)
  const leadsData = useContext(LeadsData)

  let [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: closeModalAndDelete,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["leads", leadsData.currentPage] })
      await queryClient.invalidateQueries({ queryKey: ["leads", leadsData.currentPage] })
      // ✅ update detail view directly
      const newDataArray = leadsData.data.leads.filter((lead) => lead._id !== data._id)
      leadsData.data.leads = newDataArray
      queryClient.setQueryData(["leads", leadsData.currentPage], leadsData)
    },
    onError: () => {
      queryClient.setQueryData(["leads", leadsData.currentPage], leadsData)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["leads", leadsData.currentPage] })
    },
  })

  async function closeModalAndDelete() {
    await api
      .auth(`Bearer ${jwtToken}`)
      .delete(`/leads/${idString}`)
      .fetchError((err) => console.log(err))
      .res(() => {
        leadsNotifyDeletedWithRollback(jwtToken, data, queryClient, [
          "leads",
          leadsData.currentPage,
        ])
        setIsOpen(false)
      })
      .catch((err) => console.log(err))
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="submit"
        onClick={() => openModal()}
        className="flex gap-1 rounded-lg p-1 text-sm font-medium  
                   leading-4 text-dark-gray focus:outline-none focus:ring-1"
      >
        <span>
          <Trash className="mr-1 h-4 w-4" />
        </span>
        Delete
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="mb-4 flex justify-end ">
                    <button onClick={closeModal}>
                      <XMarkIcon className="h-5 w-5 text-[##676879]" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-2 px-6 text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>
                      <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                    </span>
                    Are you sure you want to delete this file?
                  </Dialog.Title>

                  <div className="mt-14 flex justify-end gap-4 px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-gray-100 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 
                      focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={mutation.isLoading}
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-[#E24919] px-4 py-2 text-sm font-medium text-white hover:bg-red-600 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 
                      focus-visible:ring-offset-2"
                      onClick={() => mutation.mutate()}
                    >
                      {mutation.isLoading ? "Loading..." : "Delete"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

type OtherMenuProps = useMenuReducerProps & { data: TLead; idString: string }

function OtherMenu({ updateMenuState, idString, data }: OtherMenuProps) {
  return (
    <div className="min-w-[170px] rounded-lg border border-gray-200 bg-white p-2 shadow-md hover:bg-gray-100">
      <div className="flex w-full flex-col gap-y-2 ">
        <div className="flex justify-between gap-4">
          <Qualify idString={idString} data={data} />
          <button onClick={() => updateMenuState({ other: false })} className="w-fit">
            <XMarkIcon className="h-4 w-4 text-[#676879]" />
          </button>
        </div>
        <Disqualify idString={idString} data={data} />
      </div>
    </div>
  )
}

type QualifyProps = { data: TLead; idString: string }

function Qualify({ data, idString }: QualifyProps) {
  const jwtToken = useAuthStore((state) => state.token)

  const [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: closeModalAndQualify,
  })

  async function closeModalAndQualify() {
    data.status = "Qualified"

    await api
      .auth(`Bearer ${jwtToken}`)
      .patch(data, `/leads/${idString}`)
      .fetchError((err) => console.log(err))
      .res(() => {
        queryClient.invalidateQueries({ queryKey: ["leads"] })
        leadsNotifyQualify()
        setIsOpen(false)
      })
      .catch((err) => console.log(err))
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="submit"
        onClick={() => openModal()}
        className="flex w-full gap-1 rounded-lg p-1 text-sm font-medium
        leading-4 text-dark-gray focus:outline-none focus:ring-1"
      >
        <span>
          <ClipboardCheck className="mr-1 h-4 w-4" />
        </span>
        Qualify
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="mb-4 flex justify-end ">
                    <button onClick={closeModal}>
                      <XMarkIcon className="h-5 w-5 text-[##676879]" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-2 px-6 text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>
                      <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                    </span>
                    Qualify
                  </Dialog.Title>

                  <div className="mt-14 flex justify-end gap-4 px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-gray-100 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 
                      focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={mutation.isLoading}
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-[#31AA27] px-4 py-2 text-sm font-medium text-white hover:bg-green-600 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 
                      focus-visible:ring-offset-2"
                      onClick={() => mutation.mutate()}
                    >
                      {mutation.isLoading ? "Loadding..." : "Qualify"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

type DisqualifyProps = { data: TLead; idString: string }

function Disqualify({ data, idString }: DisqualifyProps) {
  const jwtToken = useAuthStore((state) => state.token)

  const [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: closeModalAndDisqualify,
  })

  async function closeModalAndDisqualify() {
    data.status = "Disqualified"

    await api
      .auth(`Bearer ${jwtToken}`)
      .patch(data, `/leads/${idString}`)
      .fetchError((err) => console.log(err))
      .json()
      .catch((err) => console.log(err))

    queryClient.invalidateQueries({ queryKey: ["leads"] })
    leadsNotifyDisqualify()
    setIsOpen(false)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="submit"
        onClick={() => openModal()}
        className="flex gap-1 rounded-lg p-1 text-sm font-medium  
        leading-4 text-dark-gray focus:outline-none focus:ring-1"
      >
        <span>
          <XCircle className="mr-1 h-4 w-4" />
        </span>
        Disqualify
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="mb-4 flex justify-end ">
                    <button onClick={closeModal}>
                      <XMarkIcon className="h-5 w-5 text-[##676879]" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-2 px-6 text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>
                      <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                    </span>
                    Qualify
                  </Dialog.Title>

                  <div className="mt-14 flex justify-end gap-4 px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-gray-100 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 
                      focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={mutation.isLoading}
                      className="inline-flex justify-center rounded-md border border-transparent 
                      bg-[#E24919] px-4 py-2 text-sm font-medium text-white hover:bg-red-600 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 
                      focus-visible:ring-offset-2"
                      onClick={() => mutation.mutate()}
                    >
                      {mutation.isLoading ? "Loading.." : "Disqualify"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Leads
