import AcademicCap from "./AcademicCap"
import Analytics from "./Analytics"
import ArrowTopRightOnSquareIcon from "./ArrowTopRightOnSquareIcon"
import Bars3 from "./Bars3"
import ClipboardCheck from "./ClipboardCheck"
import DollarInCircle from "./DollarInCircle"
import Finance from "./Finance"
import HelpCenterIcon from "./HelpCenterIcon"
import Pencil from "./Pencil"
import PencilIcon from "./PencilIcon"
import Trash from "./Trash"
import UsersIcon from "./UsersIcon"
import XCircle from "./XCircle"

export {
  AcademicCap,
  Analytics,
  ArrowTopRightOnSquareIcon,
  Bars3,
  ClipboardCheck,
  DollarInCircle,
  Finance,
  HelpCenterIcon,
  Pencil,
  PencilIcon,
  Trash,
  UsersIcon,
  XCircle,
}
