import { PlusIcon } from "@heroicons/react/24/solid"
import { ErrorMessage } from "@hookform/error-message"
import { zodResolver } from "@hookform/resolvers/zod"
import { useQueryClient } from "@tanstack/react-query"
import { ReactNode } from "react"
import { useForm } from "react-hook-form"
import {Listbox} from "src/components/ui/listbox"
import FormErrorMessage from "src/components/FormErrorMessage"
import SlideInLayout from "src/components/layouts/SlideInLayout"
import api from "src/lib/wretch"
import useAuthStore from "src/store/useAuthStore"
import useQuickFormStore from "src/store/useQuickFormStore"
import { LeadsBulkSchema, LeadsFormValues, LeadsSchema, TLead } from "src/types/Leads"
import leadsNotifyCreated, { leadsNotifyUpdated } from "./LeadsNotification"
import MultiSelect from "src/components/Form/MultiSelect"

const businesses = ["Business", "Startup", "Individual"]

const status = ["Open", "Qualified", "Disqualified"]

type LeadsFormOverlayProps = { children: ReactNode }

type QuickFormProps = { open: boolean; setOpen: () => void }

function LeadsFormOverlay(props: LeadsFormOverlayProps) {
  const openCreate = useQuickFormStore((state) => state.openCreate)
  const setOpenCreate = useQuickFormStore((state) => state.setOpenCreate)

  const openUpdate = useQuickFormStore((state) => state.openUpdate)
  const setOpenUpdate = useQuickFormStore((state) => state.setOpenUpdate)

  const openView = useQuickFormStore((state) => state.openView)
  const setOpenView = useQuickFormStore((state) => state.setOpenView)

  const openBulkUpdate = useQuickFormStore((state) => state.openBulkUpdate)
  const setOpenBulkUpdate = useQuickFormStore((state) => state.setOpenBulkUpdate)

  return (
    <>
      {props.children}

      <QuickCreateForm open={openCreate} setOpen={setOpenCreate} />
      <QuickUpdateForm open={openUpdate} setOpen={setOpenUpdate} />
      <QuickViewForm open={openView} setOpen={setOpenView} />
      <BulkUpdateForm open={openBulkUpdate} setOpen={setOpenBulkUpdate} />
    </>
  )
}

function QuickCreateForm(props: QuickFormProps) {
  const jwtToken = useAuthStore((state) => state.token)

  const { control, handleSubmit, formState, register, reset } = useForm<LeadsFormValues>({
    resolver: zodResolver(LeadsSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      city: "",
      country: "",
      businessType: "",
      service: [],
      status: "Open",
      statusReason: "-",
    },
  })

  const queryClient = useQueryClient()

  const onSubmit = async (values: LeadsFormValues) => {
    await api
      .auth(`Bearer ${jwtToken}`)
      .post(values, "/leads")
      .fetchError((err) => console.log(err))
      .res(() => {
        queryClient.invalidateQueries({ queryKey: ["leads"] })
        leadsNotifyCreated()
        props.setOpen()
        reset()
      })
      .catch((err) => console.log(err))
  }

  return (
    <SlideInLayout open={props.open} setOpen={props.setOpen} title="Create Form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <main className="flex flex-col gap-y-8 px-4">
          <div className="relative">
            <input
              id="first_name"
              type="text"
              placeholder="Enter your First Name"
              {...register("firstName", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="first_name"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              First Name
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="firstName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="last_name"
              type="text"
              placeholder="Enter your Last Name"
              {...register("lastName", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="last_name"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Last Name
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="lastName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="email"
              type="text"
              placeholder="Enter your Email"
              {...register("email", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="email"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Email
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="email"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="phoneNumber"
              type="text"
              placeholder="Enter your Phone Number"
              {...register("phoneNumber", {
                required: true,
                // eslint-disable-next-line no-useless-escape
                pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
              })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="phoneNumber"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Phone Number
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="phoneNumber"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="city"
              type="text"
              placeholder="Enter your City"
              {...register("city", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="city"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              City
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="city"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="country"
              type="text"
              placeholder="Enter your Country"
              {...register("country", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="country"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Country
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="country"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <Listbox<LeadsFormValues>
            name="businessType"
            placeholder="Select your Business Type"
            label="Business Type"
            control={control}
            rules={{ required: true }}
            data={businesses}
          />
          <ErrorMessage
            errors={formState.errors}
            name="businessType"
            render={() => <FormErrorMessage>at least 1 selected</FormErrorMessage>}
          />

          <MultiSelect name="service" control={control} />
          <ErrorMessage
            errors={formState.errors}
            name="service"
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </main>

        <div className="mt-8 flex items-center justify-end gap-8 bg-[#F1F5F9] px-4 pb-20 pt-16">
          <button
            onClick={props.setOpen}
            className="rounded-full px-8 py-2 text-base font-medium text-black hover:bg-[#E9E9E9]"
          >
            Cancel
          </button>
          <button
            type="submit"
            value="submit"
            disabled={formState.isSubmitting}
            className="rounded-full bg-white px-8 py-2 text-base 
            font-medium text-dark-gray hover:bg-[#E9E9E9] hover:text-gray-900"
          >
            {formState.isSubmitting ? "Sending" : "Save"}
          </button>
        </div>
      </form>
    </SlideInLayout>
  )
}

function QuickUpdateForm(props: QuickFormProps) {
  const jwtToken = useAuthStore((state) => state.token)
  const leadsData = useQuickFormStore((state) => state.leadsData)

  const { control, handleSubmit, formState, register } = useForm<LeadsFormValues>({
    resolver: zodResolver(LeadsSchema),
    values: {
      ...(leadsData as LeadsFormValues),
      statusReason: "-",
    },
  })

  const queryClient = useQueryClient()

  const onSubmit = async (values: LeadsFormValues) => {
    await api
      .auth(`Bearer ${jwtToken}`)
      .patch(values, `/leads/${leadsData._id as TLead}`)
      .fetchError((err) => console.log(err))
      .res(() => {
        queryClient.invalidateQueries({ queryKey: ["leads"] })
        leadsNotifyUpdated()
        props.setOpen()
      })
      .catch((err) => console.log(err))
  }

  return (
    <SlideInLayout open={props.open} setOpen={props.setOpen} title="Update Form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <main className="flex flex-col gap-y-8 px-4">
          <div className="relative">
            <input
              id="first_name"
              type="text"
              placeholder="Enter your First Name"
              {...register("firstName", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="first_name"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              First Name
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="firstName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="last_name"
              type="text"
              placeholder="Enter your Last Name"
              {...register("lastName", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="last_name"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Last Name
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="lastName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="email"
              type="text"
              placeholder="Enter your Email"
              {...register("email", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="email"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Email
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="email"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="phone_number"
              type="text"
              placeholder="Enter your Phone Number"
              {...register("phoneNumber", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="phone_number"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Phone Number
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="phoneNumber"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="city"
              type="text"
              placeholder="Enter your City"
              {...register("city", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="city"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              City
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="city"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="country"
              type="text"
              placeholder="Enter your Country"
              {...register("country", { required: true })}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="country"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Country
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="country"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <Listbox<LeadsFormValues>
            name="businessType"
            placeholder="Select your Business Type"
            label="Business Type"
            control={control}
            rules={{ required: true }}
            data={businesses}
          />
          <ErrorMessage
            errors={formState.errors}
            name="businessType"
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />

          <MultiSelect name="service" control={control} />
          <ErrorMessage
            errors={formState.errors}
            name="service"
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
           <Listbox<LeadsFormValues>
            name="status"
            placeholder="Select your Status"
            label="Status"
            control={control}
            rules={{ required: true }}
            data={status}
          />
          <ErrorMessage
            errors={formState.errors}
            name="status"
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </main>
        <div className="mt-8 flex items-center justify-end gap-8 bg-[#F1F5F9] px-4 py-16">
          <button
            type="button"
            onClick={props.setOpen}
            className="rounded-full px-8 py-2 text-base font-medium text-black hover:bg-[#E9E9E9]"
          >
            Cancel
          </button>
          <button
            type="submit"
            value="submit"
            disabled={formState.isSubmitting}
            className="rounded-full bg-white px-8 py-2 text-base 
            font-medium text-dark-gray hover:bg-[#E9E9E9] hover:text-gray-900"
          >
            {formState.isSubmitting ? "Sending" : "Update"}
          </button>
        </div>
      </form>
    </SlideInLayout>
  )
}

function QuickViewForm(props: QuickFormProps) {
  const leadsData = useQuickFormStore((state) => state.leadsData)

  const { control, register } = useForm<LeadsFormValues>({
    values: leadsData as LeadsFormValues,
  })

  return (
    <SlideInLayout open={props.open} setOpen={props.setOpen} title="View Form">
      {/* Start content */}
      <main className="flex flex-col gap-y-8 px-4 pb-20">
        <div className="relative">
          <input
            id="first_name"
            type="text"
            placeholder="Enter your First Name"
            {...register("firstName")}
            readOnly
            className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
            focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
          />
          <label
            htmlFor="first_name"
            className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
          >
            First Name
          </label>
        </div>

        <div className="relative">
          <input
            id="last_name"
            type="text"
            placeholder="Enter your Last Name"
            {...register("lastName")}
            readOnly
            className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
            focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
          />
          <label
            htmlFor="last_name"
            className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
          >
            Last Name
          </label>
        </div>

        <div className="relative">
          <input
            id="email"
            type="text"
            placeholder="Enter your Email"
            {...register("email")}
            readOnly
            className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
            focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
          />
          <label
            htmlFor="email"
            className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
          >
            Email
          </label>
        </div>

        <div className="relative">
          <input
            id="phone_number"
            type="text"
            placeholder="Enter your Phone Number"
            {...register("phoneNumber")}
            readOnly
            className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
            focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
          />
          <label
            htmlFor="phone_number"
            className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
          >
            Phone Number
          </label>
        </div>

        <div className="relative">
          <input
            id="city"
            type="text"
            placeholder="Enter your City"
            {...register("city")}
            readOnly
            className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
            focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
          />
          <label
            htmlFor="city"
            className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
          >
            City
          </label>
        </div>

        <div className="relative">
          <input
            id="country"
            type="text"
            placeholder="Enter your Country"
            {...register("country")}
            readOnly
            className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
            focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
          />
          <label
            htmlFor="country"
            className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
          >
            Country
          </label>
        </div>

        <Listbox<LeadsFormValues>
          name="businessType"
          placeholder="Select your Business Type"
          label="Business Type"
          control={control}
          rules={{ required: true }}
          data={businesses}
          readOnly={true}
        />

        <MultiSelect name="service" readOnly control={control} />

        <Listbox<LeadsFormValues>
          name="status"
          placeholder="Select your Status"
          label="Status"
          control={control}
          rules={{ required: true }}
          data={status}
          readOnly={true}
        />
      </main>
      {/* End Content */}
    </SlideInLayout>
  )
}

function BulkUpdateForm(props: QuickFormProps) {
  const jwtToken = useAuthStore((state) => state.token)
  const idArray = useQuickFormStore((state) => state.idArray)

  const { control, handleSubmit, formState, register, reset } = useForm<LeadsFormValues>({
    resolver: zodResolver(LeadsBulkSchema),
  })

  const queryClient = useQueryClient()

  const onSubmit = async (values: LeadsFormValues) => {
    // Delete Undefined or empty values
    Object.keys(values).forEach((key) => {
      values[key as keyof LeadsFormValues] === "" && delete values[key as keyof LeadsFormValues]
      values[key as keyof LeadsFormValues] === undefined &&
        delete values[key as keyof LeadsFormValues]
    })

    const payload = {
      leadIds: idArray,
      updateData: {
        ...values,
      },
    }

    await api
      .auth(`Bearer ${jwtToken}`)
      .patch(payload, "/leads/bulk")
      .fetchError((err) => console.log(err))
      .res(() => {
        queryClient.invalidateQueries({ queryKey: ["leads"] })
        leadsNotifyUpdated()
        props.setOpen()
        reset()
      })
      .catch((err) => console.log(err))
  }

  return (
    <SlideInLayout open={props.open} setOpen={props.setOpen} title="Bulk Update Form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <main className="flex flex-col gap-y-8 px-4">
          <div className="relative">
            <input
              id="first_name"
              type="text"
              placeholder="Enter your First Name"
              {...register("firstName")}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="first_name"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              First Name
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="firstName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="last_name"
              type="text"
              placeholder="Enter your Last Name"
              {...register("lastName")}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="last_name"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Last Name
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="lastName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="email"
              type="text"
              placeholder="Enter your Email"
              {...register("email")}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="email"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Email
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="email"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="phone_number"
              type="text"
              placeholder="Enter your Phone Number"
              {...register("phoneNumber")}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="phone_number"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Phone Number
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="phoneNumber"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="city"
              type="text"
              placeholder="Enter your City"
              {...register("city")}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="city"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              City
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="city"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <div className="relative">
            <input
              id="country"
              type="text"
              placeholder="Enter your Country"
              {...register("country")}
              className={`border-1 peer w-full rounded border-solid outline-none placeholder:text-xs 
              focus:border-solid focus:border-blue-400 focus:ring-blue-400`}
            />
            <label
              htmlFor="country"
              className="absolute left-[14px] top-[-10px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
            >
              Country
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="country"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>

          <Listbox<LeadsFormValues>
            name="businessType"
            placeholder="Select your Business Type"
            label="Business Type"
            control={control}
            rules={{ required: false }}
            data={businesses}
          />
          <ErrorMessage
            errors={formState.errors}
            name="businessType"
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />

          <MultiSelect name="service" control={control} rules={{ required: false }} />
          <ErrorMessage
            errors={formState.errors}
            name="service"
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
          <Listbox<LeadsFormValues>
            name="status"
            placeholder="Select your Status"
            label="Status"
            control={control}
            rules={{ required: false }}
            data={status}
          />
          <ErrorMessage
            errors={formState.errors}
            name="status"
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </main>
        <div className="mt-8 flex items-center justify-end gap-8 bg-[#F1F5F9] px-4 py-16">
          <button
            onClick={props.setOpen}
            className="rounded-full px-8 py-2 text-base font-medium text-black hover:bg-[#E9E9E9]"
          >
            Cancel
          </button>
          <button
            type="submit"
            value="submit"
            disabled={formState.isSubmitting}
            className="rounded-full bg-white px-8 py-2 text-base 
            font-medium text-dark-gray hover:bg-[#E9E9E9] hover:text-gray-900"
          >
            {formState.isSubmitting ? "Sending" : "Update"}
          </button>
        </div>
      </form>
    </SlideInLayout>
  )
}

export function AddFormButton() {
  const setOpen = useQuickFormStore((state) => state.setOpenCreate)
  return (
    <button
      type="submit"
      onClick={() => setOpen()}
      className="ml-2 flex gap-1 rounded-lg border border-purple-unfold bg-purple-unfold px-6 py-2.5
      text-sm font-medium text-white hover:bg-secondary hover:text-purple-unfold"
    >
      Add
      <span>
        <PlusIcon className="h-5 w-5 " />
      </span>
    </button>
  )
}

export default LeadsFormOverlay
