import { lazy, Suspense } from "react"
import { Outlet, useRoutes } from "react-router-dom"
import LoadingFullScreen from "./components/LoadingFullScreen"
import ProtectedRoute from "./components/ProtectedRoute"
import NotFound from "./features/404/NotFound"
import Leads from "./features/Leads/Leads"
import SignIn from "./features/SignIn/SignIn"

const LazyLeadsOpenPage = lazy(() => import("./features/Leads/LeadsOpen"))

const LazySignUpPage = lazy(() => import("./features/SignUp/SignUp"))

const LazyUsersPage = lazy(() => import("./features/users/UsersPage"))
const LazyUsersOpenPage = lazy(() => import("./features/users/UsersOpenPage"))

const LazyBetaOpenPage = lazy(() => import("./features/beta/BetaOpen"))
const LazyBetaPage = lazy(() => import("./features/beta/BetaPage"))

const LazyCareerPage = lazy(() => import("./features/career/CareerPage"))
const LazyCareerOpenPage = lazy(() => import("./features/career/CareerOpenPage"))

const LazyBlogsPage = lazy(() => import("./features/Blogs/BlogsPage"))
const LazyUpdateBlogPage = lazy(() => import("./features/Blogs/UpdateBlogPage"))
const LazyCreateBlogPage = lazy(() => import("./features/Blogs/CreateBlogPage"))
const LazyViewBlogPage = lazy(() => import("./features/Blogs/ViewBlogPage"))

const LazyJobListPage = lazy(() => import("./features/job-list/job-list-page"))
const LazyCreateJobPage = lazy(() => import("./features/job-list/create-job-page"))
const LazyJobDetailPage = lazy(() => import("./features/job-list/job-edit-page"))
const LazyJobViewPage = lazy(() => import("./features/job-list/job-view-page"))

const LazyJobAppPage = lazy(() => import("./features/job-app/job-app-page"))
const LazyJobAppReceivedPage = lazy(() => import("./features/job-app/job-app-received-page"))
const LazyJobAppViewPage = lazy(() => import("./features/job-app/job-app-view-page"))
const LazyJobAppEditPage = lazy(() => import("./features/job-app/job-app-edit-page"))


const routesConfig = [
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: (
      <Suspense fallback={<LoadingFullScreen />}>
        <LazySignUpPage />
      </Suspense>
    ),
  },
  {
    path: "dashboards",
    element: <ProtectedRoute />,
    children: [
      {
        path: "", //* Landing Page
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Leads />,
          },
          {
            path: "open/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyLeadsOpenPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "muslim-explore",
        element: <Outlet />,
        children: [
          {
            path: "career",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<LoadingFullScreen />}>
                    <LazyCareerPage />
                  </Suspense>
                ),
              },
              {
                path: "open/:id",
                element: (
                  <Suspense fallback={<LoadingFullScreen />}>
                    <LazyCareerOpenPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "beta",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyBetaPage />
              </Suspense>
            ),
          },
          {
            path: "open/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyBetaOpenPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "blogs",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyBlogsPage />
              </Suspense>
            ),
          },
          {
            path: "create",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyCreateBlogPage />
              </Suspense>
            ),
          },
          {
            path: "update/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyUpdateBlogPage />
              </Suspense>
            ),
          },
          {
            path: "view/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyViewBlogPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "users",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyUsersPage />
              </Suspense>
            ),
          },
          {
            path: "view",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyUsersOpenPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "job-list",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyJobListPage />
              </Suspense>
            ),
          },
          {
            path: "create",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyCreateJobPage />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyJobDetailPage />
              </Suspense>
            ),
          },
          {
            path: "view/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyJobViewPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "job-app",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyJobAppPage />
              </Suspense>
            ),
          },
          {
            path: "received/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyJobAppReceivedPage />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyJobAppEditPage />
              </Suspense>
            ),
          },
          {
            path: "view/:id",
            element: (
              <Suspense fallback={<LoadingFullScreen />}>
                <LazyJobAppViewPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]

const RouteRenderer = () => {
  const routes = useRoutes(routesConfig)
  return routes
}

export default function App() {
  return <RouteRenderer />
}
