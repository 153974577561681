import { Menu, Popover, Transition } from "@headlessui/react"
import {
  ArrowRightOnRectangleIcon,
  ArrowsPointingOutIcon,
  BellIcon,
  BookOpenIcon,
  BookmarkIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChatBubbleLeftIcon,
  CloudIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  ShoppingCartIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline"
import { Bars3Icon, MagnifyingGlassIcon, StarIcon, UserGroupIcon } from "@heroicons/react/24/solid"
import {
  Dispatch,
  Fragment,
  ReactNode,
  Ref,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from "react"
import { Link } from "react-router-dom"
import { api } from "../../lib/wretch"
import useAuthStore from "../../store/useAuthStore"
import {
  AcademicCap,
  Analytics,
  ClipboardCheck,
  DollarInCircle,
  Finance,
  HelpCenterIcon,
  UsersIcon,
} from "../icon"

type ShowNavProps = {
  showNav: boolean
  setShowNav: Dispatch<SetStateAction<boolean>>
}

function Layout({ children }: { children: ReactNode }) {
  const [showNav, setShowNav] = useState(true)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    //* Handler to call on window resize
    function handleResize() {
      if (window.innerWidth <= 640) {
        setShowNav(false)
        setIsMobile(true)
      } else {
        setShowNav(true)
        setIsMobile(false)
      }
    }

    //* Add event listener
    window.addEventListener("resize", handleResize)

    //* Call handler right away so state gets updated with initial window size
    handleResize()

    //* Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <div className="flex h-screen flex-col justify-between">
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} setShowNav={setShowNav} />
      </Transition>
      <main
        className={`flex-grow pb-20 pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-72" : ""
        }`}
      >
        <div>{children}</div>
      </main>
      <Footer showNav={showNav} setShowNav={setShowNav} />
    </div>
  )
}

const SideBar = forwardRef(({ showNav, setShowNav }: ShowNavProps, ref: Ref<HTMLDivElement>) => {
  return (
    <aside
      ref={ref}
      className="fixed z-[10] h-full w-72 overflow-y-scroll bg-[#111827] text-[#FAFAFA] shadow-sm"
    >
      <div className="relative mx-5 h-[80px]">
        <div
          className="fixed left-0 right-0 top-0 flex w-64 justify-between bg-[#111827] 
            pt-4"
        >
          <picture>
            <img
              src="/assets/images/logo/unfold-3.png"
              alt="unfold"
              className="ml-5 h-auto w-[107px]"
            />
          </picture>
          <div className="">
            <Bars3Icon
              className="h-6 w-6 cursor-pointer text-gray-700"
              onClick={() => setShowNav(!showNav)}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="my-6 flex justify-center">
          <picture>
            <img
              className="h-[75px] w-[75px] rounded-[50%] bg-white"
              src="/assets/images/placeholders/abbott-keitch.png"
              alt="company logo"
            />
          </picture>
        </div>
        <div className="mx-5 mb-2 flex flex-col items-center justify-center">
          <h2 className="text-center text-lg font-medium text-dark-gray">Abbott Keitch</h2>
          <p className="text-center text-dark-gray">example123@gmail.com</p>
        </div>
        <h2 className="mx-5 mb-2 py-3 pl-3 text-lg font-medium uppercase text-purple-unfold">
          Dashboard
        </h2>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <ClipboardCheck className="h-5 w-5" fill="#60697B" />
            </div>
            <div>
              <p>Projects</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <Analytics className="h-5 w-5" />
            </div>
            <div>
              <p>Analytics</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <Finance className="h-5 w-5" fill="#60697B" />
            </div>
            <div>
              <p>Finance</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <DollarInCircle className="h-5 w-5" fill="#60697B" />
            </div>
            <div>
              <p>Sales</p>
            </div>
          </div>
        </Link>

        <h2 className="mx-5 mb-2 py-3 pl-3 text-lg font-medium uppercase text-purple-unfold">
          Muslim Explore
        </h2>

        <Link to="muslim-explore/career">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors`}
          >
            <div className="mr-2 opacity-0">
              <AcademicCap className="h-5 w-5" />
            </div>
            <div>
              <p>Career</p>
            </div>
          </div>
        </Link>

        <h2 className="mx-5 my-2 py-3 pl-3 text-lg font-medium uppercase text-purple-unfold">
          APPLICATIONS
        </h2>

        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <AcademicCap className="h-5 w-5" />
            </div>
            <div>
              <p>Leads</p>
            </div>
          </div>
        </Link>
        <Link to="beta">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2 opacity-0">
              <AcademicCap className="h-5 w-5" />
            </div>
            <div>
              <p>Beta Request</p>
            </div>
          </div>
        </Link>
        <Link to="blogs">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2 opacity-0">
              <AcademicCap className="h-5 w-5" />
            </div>
            <div>
              <p>Blogs</p>
            </div>
          </div>
        </Link>
        <Link to="job-list">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2 opacity-0">
              <AcademicCap className="h-5 w-5" />
            </div>
            <div>
              <p>Job List</p>
            </div>
          </div>
        </Link>
        <Link to="job-app">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2 opacity-0">
              <AcademicCap className="h-5 w-5" />
            </div>
            <div>
              <p>Job Application</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <CalendarDaysIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Calendar</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <ChatBubbleLeftIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Chat</p>
            </div>
          </div>
        </Link>
        <Link to="users">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <UsersIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Users</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <ShoppingCartIcon className="h-5 w-5" />
            </div>
            <div>
              <p>E-Commerce</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <CloudIcon className="h-5 w-5" />
            </div>
            <div>
              <p>File Manager</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <HelpCenterIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Help Center</p>
            </div>
          </div>
        </Link>
        <Link to="">
          <div
            className={`mx-5 mb-1 flex cursor-pointer items-center rounded py-2 pl-3 text-center transition-colors `}
          >
            <div className="mr-2">
              <EnvelopeIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Mail</p>
            </div>
          </div>
        </Link>
      </div>
    </aside>
  )
})
SideBar.displayName = "SideBar"

function TopBar({ showNav, setShowNav }: ShowNavProps) {
  const reset = useAuthStore((state) => state.removeToken)

  function signOut() {
    api
      .get("/user/logout")
      .res(reset)
      .catch((err) => console.error(err))
  }

  return (
    <div
      className={`fixed z-10 flex h-16 w-full items-center justify-between bg-white shadow transition-all duration-[400ms] ${
        showNav ? "pl-72" : ""
      }`}
    >
      <div className="pl-4">
        <div className="flex">
          {!showNav && (
            <Bars3Icon
              className="mr-4 h-6 w-6 cursor-pointer text-gray-700 transition-all duration-[400ms]"
              onClick={() => setShowNav(!showNav)}
            />
          )}
          <CalendarIcon className="mr-4 h-6 w-6 cursor-pointer text-gray-700" />
          <EnvelopeIcon className="mr-4 h-6 w-6 cursor-pointer text-gray-700" />
          <UserGroupIcon className="mr-4 h-6 w-6 cursor-pointer text-gray-700" />
          <StarIcon className="mr-4 h-6 w-6 cursor-pointer text-[#F4B227]" />
        </div>
      </div>
      <div className="flex items-center pr-4">
        <Popover className="relative">
          <Popover.Button>
            <img
              src="/assets/icons/flags/usa-flag.png"
              alt="typescale"
              className="mr-5 h-5 w-auto"
            />
          </Popover.Button>
        </Popover>
        <Popover className="relative">
          <Popover.Button>
            <img src="/assets/icons/typescale.png" alt="typescale" className="mr-5 h-5 w-6" />
          </Popover.Button>
        </Popover>
        <Popover className="relative">
          <Popover.Button>
            <ArrowsPointingOutIcon className="mr-5 h-6 w-6 cursor-pointer text-gray-700" />
          </Popover.Button>
        </Popover>
        <Popover className="relative">
          <Popover.Button>
            <MagnifyingGlassIcon className="mr-5 h-6 w-6 cursor-pointer text-gray-700" />
          </Popover.Button>
        </Popover>
        <Popover className="relative">
          <Popover.Button>
            <BookmarkIcon className="relative mr-5 h-6 w-6 cursor-pointer text-gray-700" />
          </Popover.Button>
        </Popover>
        <Popover className="relative">
          <Popover.Button className="mr-5 cursor-pointer text-gray-700 outline-none">
            <BellIcon className="h-6 w-6" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform scale-95"
            enterTo="transform scale-100"
            leave="transition ease-in duration=75"
            leaveFrom="transform scale-100"
            leaveTo="transform scale-95"
          >
            <Popover.Panel
              className="absolute -left-40 z-50 mt-2 w-screen max-w-xs rounded 
            bg-white shadow-lg sm:right-4 sm:max-w-sm"
            >
              <div className="relative p-3">
                <div className="mx-2 flex w-full items-center justify-between">
                  <p className="text-xl font-medium text-gray-700">Notifications</p>
                  <button className="mr-4 text-sm text-purple-unfold">dismiss all</button>
                </div>
                <div className="m-2 grid grid-cols-1 gap-6 overflow-hidden rounded-lg px-2 py-4 shadow-md">
                  <div className="flex">
                    <div
                      className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full 
                    bg-[#F1F5F9]"
                    >
                      <StarIcon className="h-4 w-4 text-[#494F5C]" />
                    </div>
                    <div className="ml-4">
                      <p className="font-medium text-gray-700">Daily Challenges</p>
                      <p className="truncate text-sm text-gray-500">
                        Your Submission has been accepted
                      </p>
                    </div>
                  </div>
                </div>
                <div className="m-2 grid grid-cols-1 gap-4 overflow-hidden rounded-lg px-2 py-4 shadow-md">
                  <div className="flex">
                    <div
                      className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full 
                    bg-[#F1F5F9]"
                    ></div>
                    <div className="ml-4">
                      <p className="truncate text-sm text-gray-500">
                        Leo Gill Added you to top secret project group and....
                      </p>
                    </div>
                  </div>
                </div>
                <div className="m-2 grid grid-cols-1 gap-4 overflow-hidden rounded-lg px-2 py-4 shadow-md">
                  <div className="flex">
                    <div
                      className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full 
                    bg-[#F1F5F9]"
                    >
                      <EnvelopeIcon className="h-4 w-4 text-dark-gray" />
                    </div>
                    <div className="ml-4">
                      <p className="font-medium text-gray-700">Mailbox</p>
                      <p className="truncate text-sm text-gray-500">
                        You have 15 unread mails across 3 mailboxes
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full items-center justify-center">
              <div>
                <h2 className="text-center text-sm font-medium text-black">Abbott Keitch</h2>
                <p className="text-center text-xs text-dark-gray">example123@gmail.com</p>
              </div>
              <picture>
                <img
                  src="/assets/images/placeholders/abbott-keitch.png"
                  className="ml-2 h-8 rounded-full border-2 border-white shadow-sm md:ml-4"
                  alt="profile"
                />
              </picture>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform scale-95"
            enterTo="transform scale-100"
            leave="transition ease-in duration=75"
            leaveFrom="transform scale-100"
            leaveTo="transform scale-95"
          >
            <Menu.Items
              className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded bg-white 
              shadow-lg"
            >
              <div className="p-1">
                <Menu.Item>
                  <Link
                    to="/dashboard"
                    className="group flex items-center rounded p-2 text-sm text-gray-700 
                    transition-colors hover:bg-dark-gray hover:text-white"
                  >
                    <UserCircleIcon className="mr-2 h-4 w-4" />
                    My Profile
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/dashboard"
                    className="group flex items-center rounded p-2 text-sm text-gray-700 
                    transition-colors hover:bg-dark-gray hover:text-white"
                  >
                    <EnvelopeOpenIcon className="mr-2 h-4 w-4" />
                    Inbox
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <button
                    type="button"
                    onClick={() => signOut()}
                    className="group flex w-full items-center rounded p-2 text-sm text-gray-700 
                    transition-colors hover:bg-dark-gray hover:text-white"
                  >
                    <ArrowRightOnRectangleIcon className="mr-2 h-4 w-4" />
                    Sign Out
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}

function Footer({ showNav, setShowNav }: ShowNavProps) {
  return (
    <div
      className={`fixed inset-x-0 bottom-0 z-[9] flex h-16 w-full items-center justify-between 
      bg-[#111827] transition-all duration-[400ms] ${showNav ? "pl-72" : ""}`}
    >
      <div className="ml-4 flex gap-2">
        <button className="mr-5 flex gap-2 rounded-3xl bg-purple-unfold px-4 py-2 text-white">
          <span>
            <ShoppingCartIcon className="h-6 w-6" />
          </span>
          Purchase FUSE React
        </button>
        <button className="flex gap-2 rounded-3xl bg-dark-gray px-4 py-2 text-white">
          <span>
            <BookOpenIcon className="h-6 w-6" />
          </span>
          Documentation
        </button>
      </div>
      <div className="flex items-center">
        <picture>
          <img src="/logo192.png" className="mr-2 h-8 md:mr-4" alt="profile" />
        </picture>
        <picture>
          <img
            src="/assets/images/logo/tailwindcss.png"
            className="mr-2 h-auto md:mr-4"
            alt="profile"
          />
        </picture>
        <picture>
          <img src="/assets/images/logo/redux.png" className="mr-2 h-auto md:mr-4" alt="profile" />
        </picture>
        <picture>
          <img src="/assets/images/logo/mui.png" className="mr-2 h-auto md:mr-4" alt="profile" />
        </picture>
      </div>
    </div>
  )
}

export default Layout
